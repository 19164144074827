<template>
    <div class="livePlayer">
        <div class="sdk-wrap clearfix">
            <div class="sdk-wrap-left">
                <div id="ppt" class="mainScreen"></div>
                <div id="player" class="subScreen"></div>
            </div>
            <div id="wrap" class="chatroom"></div>
        </div>
        
    </div>
</template>


<script>
import { setUpload, dayiAdd } from '@/api/selectClass.js';
import md5 from 'js-md5';
import { post } from '@/utils/ajax.js';
import jiangYi from '@/components/jiangYi';
import { liveYuYue } from "@/api/home.js";
import videoplayer from '@/components/videoPlayer'
import {playback} from '@/api/selectClass.js'

export default {
    components: { jiangYi, videoplayer },
    props: {
        vid: {
            type: String,
            default: ''
        },
        courseId: {
            type: String,
            default: ''
        },
        channelId: {
            type: String,
            default: ''
        },
        coursePackageId: {
            type: String,
            default: ''
        },
        classHourId: {
            type: Number,
            default: ''
        },
        isYY: {
            type: Boolean,
            default: ''
        },
        isEnd: {
            type: Boolean,
            default: ''
        },
        startTimes: {
            type: String,
            default: ''
        },
        zhiboId: {
            type: String,
            default: ''
        },
        isFree: {
            type: Number,
            default: ''
        }
    },
    data() {
        return {
            chatroom:null,
            liveSdk:null
        };
    },
    // watch:{
    //     vid(newVal,oldVal){
    //         // console.log('videoPlayer监听器---',newVal,oldVal)
    //         this.playerSafeFun()
    //     }
    // },
    mounted() {
       this.init();
    },
    methods: {
        onYuYue() {
            let data = new FormData()
            data.append('liveId', this.zhiboId)
            liveYuYue(data).then(res => {
                if (res.data.code == 0) {
                    this.$toast('预约直播成功');
                    this.yyText = '待开播';
                }else if(res.data.code == 1){
                    this.$toast('已预约成功，无需重复预约');
                    this.yyText = '待开播';
                }
            })
        },
        checkTime(i) {
            if (i < 10 && i != 0) {
                i = "0" + i;
            }
            return i;
        },
        myFunction() {
            var nowDate = new Date();
            var EndDate = new Date(this.startTimes);
            var dateDiff = EndDate.getTime() - nowDate.getTime();
            var days = Math.floor(dateDiff / (24 * 3600 * 1000)) //计算出天数 
            //计算出小时数 
            var leave1 = dateDiff % (24 * 3600 * 1000)  //计算天数后剩余的毫秒数 
            var hours = Math.floor(leave1 / (3600 * 1000))
            //计算相差分钟数 
            var leave2 = leave1 % (3600 * 1000)    //计算小时数后剩余的毫秒数 
            var minutes = Math.floor(leave2 / (60 * 1000))
            //计算相差秒数 
            var leave3 = leave2 % (60 * 1000)   //计算分钟数后剩余的毫秒数 
            var seconds = Math.round(leave3 / 1000)
            //调用checkTime方法将数字小于10的在前面补0
            days = this.checkTime(days);
            hours = this.checkTime(hours);
            minutes = this.checkTime(minutes);
            seconds = this.checkTime(seconds);

            this.timesInnerText = "剩余时间:" + days + "天" + hours + "小时" + minutes + "分钟" + seconds + "秒";
            const _that = this;
            if(days == 0 && hours == 0 && minutes == 0 && seconds == 0){
                this.$emit('allEventFn',{type:'bf'}); 
            }   
            setTimeout(function () { _that.myFunction(), 1000 })
        },
        subMitFn() {
            if (this.form.questionContent == null || this.form.questionContent == "") {
              
                this.$toast('请输入问题!');
            } else {
                let urls = [];
                this.fileList.map((item) => {
                    urls.push(item.url);
                })

                let data = new FormData();
                data.append("classHourId", this.classHourId);
                data.append("courseId", this.courseId);
                data.append("coursePackageId", this.coursePackageId);
                data.append("questionContent", this.form.questionContent);
                data.append("questionPics", urls.join(","));
                dayiAdd(data).then((res) => {
                    console.log("Res", res);
                    if (res.data.code == 0) {

                        this.$toast('提交成功!');
                    } else {
                        this.$toast(res.data.msg);
                    }

                })



            }

        },
        afterRead(file) {
            // 此时可以自行将文件上传至服务器
            let formData = new FormData();
            formData.append('files', file.file);
            setUpload(formData).then((res) => {

                this.lists.push({
                    url: res.data.data[0]
                });
                this.fileList = this.lists;

            });


        },
       
        getChatToken() {
            var chatData = {};
            var appId = "fmkz0ciqw1";
            //var channelId="3282616";
            var apiUrl = "https://api.polyv.net/live/v3/channel/common/get-chat-token";
            var params = {
                appId: appId, // 账号appId
                channelId: this.channelId, // 频道号
                role: "v88083abbf5bfc681ce7d8cdfb32e73f7_8", // 角色,viewer：观看者
                timestamp: new Date().getTime(),
                userId: "a7a317c4dc", // 观看者用户Id
            };
            params.sign = this.getSign(params);
            console.log("params", params);
            var apiUrls = "https://api.polyv.net/live/v3/channel/common/get-chat-token?appId=" + appId + "&channelId=" + params.channelId + "&role=" + params.role + "&timestamp=" + params.timestamp + "&userId=" + params.userId + "&sign=" + params.sign;
            return new Promise(function (resolve) {
                post(apiUrls, null, function (result) {
                    let datas = JSON.parse(result);
                    if (datas.code == 200) {
                        resolve(datas.data);
                    }
                })
            })
        },
        getSign(params) {
            var paramsStr = "";
            for (let key in params) {
                paramsStr += key + "" + params[key]
            }
            var appSecret = "76e906eb039e4c05b4c1d707b368d1b6";
            var finalStr = appSecret + paramsStr + appSecret;
            return md5(finalStr).toUpperCase();
        },
        async init() {

            var appId="fmkz0ciqw1";var channelId="3282616";var appSecret="76e906eb039e4c05b4c1d707b368d1b6";
//参数结束
        var userId = "test-001";
        var userName = '测试-001';
        var userPic = 'http://livestatic.videocc.net/assets/wimages/missing_face.png';
        var chatData = await this.getChatToken(); //从接口获取聊天室token
        //第一步：初始化聊天室
        var chatroom = new PolyvChatRoom({
          roomId: channelId,
          userId: userId,
          nick: userName,
          pic: userPic,
          token: chatData.token,
          mediaChannelKey: chatData.mediaChannelKey,
          version: "2.0",
          container: "#wrap",
          width: 300,
          height: 600,
          userType: "slice",
          roomMessage: function (data) {
            // data为聊天室socket消息，当有聊天室消息时会触发此方法
            console.log(data);
          },
        });
        var params = {
          appId: appId,
          channelId: channelId,
          timestamp: new Date().getTime(),
        };
        var sign = this.getSign(params);
        //第二步：初始化直播sdk对象
        var liveSdk = new PolyvLiveSdk({
          channelId: channelId,
          sign: sign, // 频道验证签名
          timestamp: params.timestamp, // 毫秒级时间戳
          appId: appId, // polyv 后台的appId
          socket: chatroom.chat.socket,
          user: {
            userId: userId,
            userName: userName,
            pic: userPic,
          },
        });

        // 监听频道信息并初始化播放器
        liveSdk.on(PolyvLiveSdk.EVENTS.CHANNEL_DATA_INIT, (event, data) => {
          //第三步：设置直播播放器
          liveSdk.setupPlayer({
            pptEl: "#ppt",
            el: "#player",
            type: "auto",
            controllerPosition: "ppt",
            pptNavBottom: "80px",
            autoplay: true,
          });
        });
        },
    },
    destroyed() {
        // if (this.player) {
        //     this.player.destroy();
        // }
    }
};
</script>

<style lang="less" scoped>
.clearfix:before, .clearfix:after {
        content: " ";
        display: table;
      }
      .clearfix:after {
        clear: both;
      }
      .sdk-wrap-left {
        float: left;
        width: 400px;
        height: 233px;
        position: relative;
      }
      .mainScreen{
        width: 260px;
        height: 80px;
        position: absolute;
        bottom:10px;
        right: 10px;
        z-index: 99;
      }
      .subScreen{
        width: 100%;
        height: 233px;
        z-index: 100;
      }
      .chatroom{
        float: left;
        width:300px;
        height: 300px;
      }
</style>


