<template>
    <div class="livePlayer">
       
        <div  class="player_yy">
            <div id="ppt" @click="pptClickFn" v-if="liveDomType == 'ppt'" :class="isfirst ? 'mainScreen' : 'subScreen' "></div>
            <div id="player" @click="playerClickFn" :class="isfirst ? 'subScreen' : 'mainScreen' " ></div>
           
        </div>

        <van-tabs v-model="activeName" color="#2470F4">
            <van-tab title="大厅">
                <div id="wrap"></div>
            </van-tab>
            <van-tab title="讲义">
                <div class="tabDiv jyBox">
                    <jiangYi :courseId="courseId" />
                </div>
            </van-tab>
            <van-tab title="问答" v-if="this.isFree === 1 && this.classHourId != -1000">
                <div class="livePlayer_continer">
                    <div>
                        <span class="xinnghao">*</span>
                        <span class="question">问题</span>
                        <span class="detail_span">（针对本课程提问）</span>
                    </div>
                    <div style="margin-top:12px;">
                        <van-field v-model="form.questionContent" rows="2" autosize type="textarea" maxlength="300" placeholder="一句话准确描述您的问题" show-word-limit />
                    </div>
                    <div style="margin-top:12px;">
                        <span class="question">上传图片</span>
                        <span class="detail_span">（最多三张）</span>
                    </div>
                    <div style="margin-top:12px;">
                        <van-uploader :upload-icon="imgUrl" v-model="fileList" :after-read="afterRead" />
                    </div>
                    <div class="sub_class">
                        <div style="width:100%;height:11px"></div>
                        <div class="sub_box" @click="subMitFn">提交</div>
                    </div>

                </div>

            </van-tab>
        </van-tabs>

    </div>
</template>


<script>
import { setUpload, dayiAdd } from '@/api/selectClass.js';
import md5 from 'js-md5';
import { post } from '@/utils/ajax.js';
import jiangYi from '@/components/jiangYi';
import { liveYuYue } from "@/api/home.js";
import videoplayer from '@/components/videoPlayer'
import {playback,playbackByChannelId,watchLive,getliveModeByChannelAndType,playbackReportedData} from '@/api/selectClass.js'
import {kbt} from '@/utils/utils.js'
export default {
    components: { jiangYi, videoplayer },
    props: {
        vid: {
            type: String,
            default: ''
        },
        courseId: {
            type: String,
            default: ''
        },
        channelId: {
            type: String,
            default: ''
        },
        coursePackageId: {
            type: String,
            default: ''
        },
        classHourId: {
            type: Number,
            default: ''
        },
        isYY: {
            type: Boolean,
            default: ''
        },
        isEnd: {
            type: Boolean,
            default: ''
        },
        startTimes: {
            type: String,
            default: ''
        },
        zhiboId: {
            type: String,
            default: ''
        },
        isFree: {
            type: Number,
            default: ''
        }
    },
    data() {
        return {
            fileList: [],
            activeName: 0,
            uid: 'a7a317c4dc',
            form: {
                questionContent: ''
            },
            imgUrl: require("@/assets/all_Icon/drawable-xxhdpi/bg_add_image.png"),
            lists: [],
            timesInnerText: '',
            yyText: '预约',
            chatroom:'',
            videoState:'', //回放状态
            isfirst:true,
            state:'',
            queryObj:'',
            liveSdk:'',
            liveDomType:'',
            bottomTimes:'',
            clearTimeSet: null, 
            seekLength:0,
            browseTime: 0,  // 浏览时长初始值为 0
            plackRateNumber:1,
        };
    },
    created() {
       

    
    },
    mounted() {
        this.queryObj = this.$route.params;
        this.fileList = [];
        this.state = this.$route.query.state;
        if(this.state == 2){
            window.addEventListener("beforeunload", (e) => this.beforeunloadHandler(e));
            let channelId = kbt.decrypt(this.$route.query.id) //kbt.decrypt解密
            let data =new FormData()
            data.append('channelId',channelId)
            playbackByChannelId(data).then(res =>{
                if(res.data.code == 0){
                    if (res.data.data.contents.length == 0) {
                        this.videoState = "";
                        this.liveDomType = "ppt";
                        this.title = this.$route.query.title;
                    } else {
                        this.videoState = res.data.data.contents[0].videoPoolId;
                        this.title = res.data.data.contents[0].title;
                        this.liveDomType = res.data.data.contents[0].liveType;
                        if(this.state == "2"){
                            this.seekLength = res.data.data.lastStudyTimeLength;
                        }
                    }  
                    
                }
                this.init();
            })

        }else{
           
            let data =new FormData()
            data.append('channelId',kbt.decrypt(this.$route.query.id));
            data.append('liveType',this.$route.query.state);
            getliveModeByChannelAndType(data).then((res)=>{
                if(res.data.code == 0){
                    this.title = this.$route.query.title;
                    this.liveDomType = res.data.data == 1 ? 'ppt' : 'alone';
                }else{
                    this.liveDomType = "ppt";
                    this.title = this.$route.query.title;

                }
                this.init();
                
            })

        }
        
    },
    beforeDestroy() {
        this.liveSdk.destroy(true);
        window.removeEventListener('beforeunload', this.beforeunloadHandler);
        clearInterval(this.clearTimeSet);
        this.playbackReportedData_new();
    },
    methods: {
        beforeunloadHandler(e){
            if (e) {
                e = e || window.event;
                this.playbackReportedData_new();
                if (e) {
                // e.returnValue = "关闭提示";
                }
            // return "关闭提示";
            }
        },
        getCurLength(){
            let times = this.liveSdk.player.currentTime;
            return times;
        },
        playbackReportedData_new() {
           
           let data = new FormData()
           data.append('coursePackageId', this.$route.query.coursePackageId);
           data.append('courseId', this.$route.query.courseId);
           data.append('classHourId', this.$route.query.classId);
           data.append('videoPlan',  Math.ceil(this.browseTime));
           data.append('videoPlanLength',  Math.ceil(this.getCurLength()));
           playbackReportedData(data)
               .then((res) => {
               // console.log(res);
               })
               .catch((error) => {
               console.log(error);
           });
        },

        
        watchLive() {
            let id = this.queryObj.id;
            watchLive({ liveId: id });
        },
        initTypeFun(){

        },
        playerClickFn(){
            this.isfirst = true;
        },
        pptClickFn(){
            this.isfirst = false;
        },
        onYuYue() {
            let data = new FormData()
            data.append('liveId', this.zhiboId)
            liveYuYue(data).then(res => {
                if (res.data.code == 0) {
                    this.$toast('预约直播成功');
                    this.yyText = '待开播';
                }else if(res.data.code == 1){
                    this.$toast('已预约成功，无需重复预约');
                    this.yyText = '待开播';
                }
            })
        },
        checkTime(i) {
            if (i < 10 && i != 0) {
                i = "0" + i;
            }
            return i;
        },
        subMitFn() {
            if (this.form.questionContent == null || this.form.questionContent == "") {
              
                this.$toast('请输入问题!');
            } else {
                let urls = [];
                this.fileList.map((item) => {
                    urls.push(item.url);
                })

                let data = new FormData();
                data.append("classHourId", this.classHourId);
                data.append("courseId", this.courseId);
                data.append("coursePackageId", this.coursePackageId);
                data.append("questionContent", this.form.questionContent);
                data.append("questionPics", urls.join(","));
                dayiAdd(data).then((res) => {
                    if (res.data.code == 0) {

                        this.$toast('提交成功!');
                    } else {
                        this.$toast(res.data.msg);
                    }

                })



            }

        },
        afterRead(file) {
            // 此时可以自行将文件上传至服务器
            let formData = new FormData();
            formData.append('files', file.file);
            setUpload(formData).then((res) => {

                this.lists.push({
                    url: res.data.data[0]
                });
                this.fileList = this.lists;

            });
        },
       
        getChatToken() {
            var chatData = {};
            var appId = "fmkz0ciqw1";
            //var channelId="3282616";
            var apiUrl = "https://api.polyv.net/live/v3/channel/common/get-chat-token";
            let channelId = kbt.decrypt(this.$route.query.id) //kbt.decrypt解密
            var params = {
                appId: appId, // 账号appId
                channelId: channelId, // 频道号
                role: "v88083abbf5bfc681ce7d8cdfb32e73f7_8", // 角色,viewer：观看者
                timestamp: new Date().getTime(),
                userId: "a7a317c4dc", // 观看者用户Id
            };
            params.sign = this.getSign(params);
            var apiUrls = "https://api.polyv.net/live/v3/channel/common/get-chat-token?appId=" + appId + "&channelId=" + params.channelId + "&role=" + params.role + "&timestamp=" + params.timestamp + "&userId=" + params.userId + "&sign=" + params.sign;
            return new Promise(function (resolve) {
                post(apiUrls, null, function (result) {
                    let datas = JSON.parse(result);
                    if (datas.code == 200) {
                        resolve(datas.data);
                    }
                })
            })
        },
        setTime() {
            //设置定时器
            this.clearTimeSet = setInterval(() => {
            this.browseTime = this.browseTime + this.plackRateNumber;
            }, 1000);
        },
        getSign(params) {
            var paramsStr = "";
            for (let key in params) {
                paramsStr += key + "" + params[key]
            }
            var appSecret = "76e906eb039e4c05b4c1d707b368d1b6"; 
            var finalStr = appSecret + paramsStr + appSecret;
            return md5(finalStr).toUpperCase();
        },
        async init() {

            var appId = "fmkz0ciqw1";
            var appSecret = "76e906eb039e4c05b4c1d707b368d1b6";
            var channelId = kbt.decrypt(this.$route.query.id);
            let userinfo = JSON.parse(localStorage.getItem("userInfo"));
            var userId = userinfo.data.id;
            var userName = userinfo.data.nickName || "测试-01";
            var userPic = 'http://livestatic.videocc.net/assets/wimages/missing_face.png';
            //4.需要先请求以下接口获取token和mediaChannelKey，传给js-sdk
            var chatData = await this.getChatToken(); //从接口获取聊天室token
            //5、新建对象
            this.chatroom = new PolyvChatRoom({
                roomId: channelId,
                userId: userId,
                nick: userName,
                pic: userPic,
                token: chatData.token,
                mediaChannelKey: chatData.mediaChannelKey,
                version: '2.0',
                container: '#wrap',
                width: '100%',
                height: window.innerHeight - 323,
                userType: 'slice',
                roomMessage: function (data) {
                    // TODO
                    // data为聊天室socket消息，当有聊天室消息时会触发此方法
                    console.log(data);
                }
            });
            
            var params = {
                appId: appId,
                channelId: channelId,
                timestamp: new Date().getTime(),
            };
            var sign = this.getSign(params);
            //第二步：初始化直播sdk对象
            this.liveSdk = new PolyvLiveSdk({
                    channelId: channelId,
                    sign: sign, // 频道验证签名
                    timestamp: params.timestamp, // 毫秒级时间戳
                    appId: appId, // polyv 后台的appId
                    socket: this.chatroom.chat.socket,
                    user: {
                        userId: userId,
                        userName: userName,
                        pic: userPic,
                    },
            });
            this.liveSdk.on(PolyvLiveSdk.EVENTS.CHANNEL_DATA_INIT, (event, data) => {
                //第三步：设置直播播放器
                var obj = {};
                if (this.state == "2") {
                    obj = {
                        type: "vod",
                        vid: this.videoState,
                        pptEl: "#ppt",
                        el: "#player",
                        controllerPosition: "ppt",
                        controller:false,
                        pptNavBottom: "80px",
                        autoplay: true,
                        vodType: "vod",
                        pptPlaceholder:true,
                        pptNav:true,
                        barrage:true,
                        pptWidth:'100%',
                        pptHeight:'100%',
                    };
                } else {
                    obj = {
                        type: "auto",
                        //fileId:this.fileId,
                        //url:hFurl,
                        //sessionId:this.sessionId,
                        pptEl: "#ppt",
                        el: "#player",
                        controllerPosition: "ppt",
                        controller:false,
                        pptNavBottom: "80px",
                        autoplay: true,
                        pptNav:true,
                        pptPlaceholder:true,
                        barrage:true,
                        pptWidth:'100%',
                        pptHeight:'100%',
                    };
                }
                
                this.liveSdk.setupPlayer(obj);
                var _that = this;
                
                this.liveSdk.player.on('loadedmetadata', function () {

                    if (_that.state == "2") {
                        console.log(_that.seekLength);
                        _that.liveSdk.player.seek(_that.seekLength);
                    }
                    _that.yinliangValue = 100;
                });
                this.liveSdk.player.on('ratechange', function (event) {
                    _that.plackRateNumber =  _that.liveSdk.player.playbackRate;
                });
                this.liveSdk.player.on('playing', function () {
                    console.log("恢复播放");
                    if(_that.state == "2" ){
                        _that.setTime();

                    }
                });

                this.liveSdk.player.on('pause', function () {
                    if(_that.state == "2"){
                        clearInterval(_that.clearTimeSet);
                    }
                });

                this.liveSdk.on(PolyvLiveSdk.EVENTS.STREAM_UPDATE, function(event, status) {
                    _that.liveSdk.reloadPlayer();
                });
            });
        },
    },
    destroyed() {
        // if (this.player) {
        //     this.player.destroy();
        // }
    }
};
</script>

<style lang="less" scoped>
/deep/ .pv-ppt-control-layout{
    display: none;
}
.mainScreen{
        width: 160px;
        height: 80px;
        position: absolute;
        bottom:50px;
        right: 10px;
        z-index: 1000;
      }
      .subScreen{
        width: 100%;
        height: 233px;
        z-index: 800;
      }
.livePlayer {
    .player_yy {
        width: 100%;
        height: 233px;
        background: #141414;
        position: relative;
        .silder_box{
            width:100%;
            height: 40px;
            position: absolute;
            bottom: 0px;
            z-index: 1000000;
            background: black;
            display:flex;
            flex-direction: row;
            .btn-box{
                display: flex;
                align-items: center;
            }
            .videoQp{
                width: 50px;
                height: 40px;
                line-height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                div{
                    position: relative;
                    float: right;
                    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAwCAYAAABT9ym6AAABM0lEQVRoQ+2Z0Q3CMAxEzyvwB2IHVmANGIQhGATWYAV2QPSPFYKCQGqjkkQXuSnB/Y5j3ztHSmNBI580ogMmZG5ORh1xzq0BHAFsASxjxYtIkbvOOZeA0wG4ADiIyC1c+zX5W8QVwCKH/gRCPmU8AGxCMTEhJwC7HBF+zYRCfLqziOz7tcWE3FPtNNhIv7X66ToRWeUKGfRsKfFcZ7+tC89QWE/MERNSSn8s3hxJEdCgHtszVY+dEXOEJEC3FpmvWljRRa9a1SOJTcic3HhdWudWEFuPCWHJacWZI1pk2X3NEZacVpw5okWW3dccYclpxbXvSOpHRousvWuxBMwRkkCq1e05iARLh5kjKQI0WjIwVc9fDHqaGb35ie7vD0N9KzcxnibPZLWw9m+/1dCSiZtx5Ak8Sc4xYB6f4gAAAABJRU5ErkJggg==) no-repeat;
                    background-size: 24px;
                    width: 24px;
                    height: 24px;
                }
                div:hover{
                    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAABDUlEQVRoQ+2ZQQ7CIBREIR7ApGu9hnoFL+LGHqduvIhXUK+h6yYewGBYmCg0/ZBJaD+ZbsuHP/MmpQFrlD9Wef+GAqYmGBHYta+9M++zc2aV0tz91EAUN8fepaxjrXlaszhcu+Xld3y0+LbtH6nN+4lKCfBreRG3rlmPCkh15DtJSQFDhkUEqhOAOpyS77ExoaFhPyIBCgARkAAjxAj979T1f4VA4sXLoR+x4t0OLEgBU1MgARIAHWCEQAPhchKALQQnIAHQQLicBGALwQnqIyCdAoCGZZdL/fBcKNvSzAIS4LlQZmTC4YyQ+ghJCUAFohcq4j6gToD6Sz7116xSZOb2vr6/0bk5LPWjnsAH/orSMbiaHvkAAAAASUVORK5CYII=) no-repeat;
                    background-size: 24px;
                    width: 24px;
                    height: 24px;
                }
            }
            .time_box{
                font-size: 14px;
                color: #ffffff;
                display: flex;
                align-items: center;
                margin-left: 10px;
            }
            .slider_box_child{
                margin-left: 10px;
                width:150px;
                height: 40px;
                display: flex;
                align-items: center;
            }
            .stopBox{
              
              background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAACD0lEQVRoQ+2Zv0odQRSHv98jWIek8CHyBHkC8wgW6WITghgUggbypxCLQBAsBBsl3sZGSCBGhFQpQoIgGkFELMR4RVREZeTALsgl3J29987ODtxpd3bn+/bMnNk5KxJvSpyfvkDsCPYjkEQEnHMPgBngSQb8BZiS9Kv2As65R8A3YLAF9haYBcYlHccSKVwDzrllYKgN4IlJAJ8kmVSlzUfgHzDgQfUbGJFk0aqs+Qi4kjSfgReS9kre11H3EAIGcgl8AN5JuuiIzPOmUAL58PtZNJY8eUp3Cy2QA60Dz0Ok3aoETCRI2q1SII9GT9NuDIFc5E82rbpKuzEFcpGu0m4dBLpKu3UR6Djt1k0gF/kODEv6W7Qx1FXAuC1bPZa0006izgLG3ZD0NGWBpqS2X8J1j0DyAklPoWa2iLdTXANfgWeSdlNLo1blGJW0WgSeX6/LIrbjpxUGFiSVOsLGFrCCwRvgo6Qr37d+v18sATszW6HsraTTTsBjTSE7lc0DE5IOugGPIbCSLdDNXoBXKfADeClpo5fgVQhsAWOSGiHAQwocAq+BOUk3IeHt2T5ZyLc2ega8B6YlnYcGLxOBour0tVWmgUlJR1WBlxF4CKz95/+A7ZiLwCufb5ZQYoVTyAZ2zpnEdMsfGtuEfoYC832ul4Dvw2L06wvEeOulPuZiAxaN359CRW8o9PXkI3AH4FDcMXntc9QAAAAASUVORK5CYII=) no-repeat;
              background-size: 24px;
              width: 24px;
              height: 24px;
              margin-left: 15px;
            }
            .startBox{
              
              background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAA0ElEQVRoQ+2Zyw0CMRBD7T6gIBqhKBqhILaP4cQFocxKVhQivb1G6/naM1GszT9v7r8IYHUFqcDfV6CqbpIeki5fzh6S7rafSRApfttCVfX64fzH58P2NQwgwj8TQI0ctN1ijP6vqgi/NZ4a6KqT4hMALQQHGpalJIPEjUyjQqgQKoQKscyxjQ5ZwCSeLBJMYiYxk3gyybgPcB8ISUYL0UK00JgF6bLILrTDLhQ9QJyQ0Qj/TAvt/cTUZXD1eVuB1Q529gmgy9DscyowO8Md/hvOMSxAGNSCbAAAAABJRU5ErkJggg==) no-repeat;
              background-size: 24px;
              width: 24px;
              height: 24px;
              margin-left: 15px;
            }
            /deep/ .van-slider__button{
                width:8px;
                height: 8px;
                border: 2px solid rgba(9,237,28,.92);
            }
        }
        
    }
    .sub_box {
        margin: 0px 14px;
        background: #5f7dff;
        height: 36px;
        color: #ffffff;
        border-radius: 5px;
        text-align: center;
        line-height: 36px;
    }
    .tabDiv {
        height: 53vh;
        overflow: auto;
        padding: 10px 16px;
    }
    .sub_class {
        position: absolute;
        bottom: 0px;
        right: 0px;
        width: 100%;
        height: 60px;
        background-color: #ffffff;
    }
    .livePlayer_continer {
        //height: calc(100vh - 358px);
        padding: 14px 14px;
        position: relative;
        height: calc(100vh - 352px);
    }
    /deep/ .van-cell {
        border-radius: 5px;
    }
    /deep/ .van-icon__image {
        width: 80px;
        height: 80px;
    }
    /deep/ .van-field__control {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
    }
    .xinnghao {
        color: #e02727;
    }
    .question {
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #141414;
    }
    .detail_span {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
    }
    // /deep/ .polyv-cr-head {
    //     display: none;
    // }
    // /deep/ .polyv-chat-room {
    //     padding-top: 0px;
    // }
}
</style>


