<template>
    <div class="videoPlay">
        <!-- 头部导航 -->
        <van-nav-bar class="navBar" fixed :placeholder="true" left-arrow @click-left="$router.go(-1)">
            <template #title>
                <span>视频直播</span>
            </template>
            <template #right>
                <van-icon size="22" name="wap-home-o" @click="$router.push('/')" />
            </template>
        </van-nav-bar>

        <!-- 视频播放 -->
        <div v-if="showPlayerVisible">
            <livePlayer :vid="videoId" :zhiboId="zhiboId" :key="timer" @allEventFn="allEventFn" :isFree="isFree" :courseId="courseId" :startTimes="startTimes" :isEnd="isEnd" :isYY="isYY" :classHourId="Number(classHourId)"  :coursePackageId="coursePackageId" :channelId="channelId"/>
        </div>
        <div v-else>
            <van-empty image="error" description="请购买套餐观看" />
        </div>

        

    </div>
</template>

<script>
import livePlayer from '@/components/livePlayer';
import livePlayerNew from '@/components/livePlayer_new';
import { packageDetail ,live_isBuy} from '@/api/selectClass.js';
import {kbt} from '@/utils/utils.js'


export default {
    components:{ livePlayer,livePlayerNew },
    data() {
        return {
            videoId:'',
            courseId:'',
            channelId:'',
            coursePackageId:'',
            classHourId:'',
            startTimes:'',
            endTimes:'',
            isYY:false,
            isEnd:false,
            timesInnerText:'',
            zhiboId:'',
            timer: '',
            isFree:1,
            showPlayerVisible:true

        }
    },
    activated(){
        console.log("-----activated")
    },
    created() { //talon edit 0915
       
        // this.setYYFn();
        console.log("-----视频直播页面");
       
        
    },
    mounted() {
        this.coursePackageId = this.$route.params.coursePackageId;
        this.courseId = this.$route.params.courseId;
        this.zhiboId = this.$route.params.id;
        this.videoId = this.$route.params.channelId;
        this.channelId = this.$route.params.channelId;
        console.log('this.$route.params.channelId',this.$route.query.id);
        this.live_isBuy();
    },
    methods: {
        live_isBuy() {
            let channelId = kbt.decrypt(this.$route.query.id) //kbt.decrypt解密
            let data =new FormData()
            data.append('channelId',channelId)
            live_isBuy(data).then(res => {
                if (res.data.code == 0) {
                    let isBuy = res.data.data.isBuy;
                    if (isBuy == 1) {
                        this.showPlayerVisible = true;
                       
                    } else {
                        // this.$message.error('您还未购买当前套餐');
                        // this.$router.replace('/');
                        this.showPlayerVisible = false;
                    }
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        },
        allEventFn(res){
            if(res.type == 'bf'){
                this.isYY = false;
                this.timer = new Date().getTime();

            }
        },
        setYYFn(){
            // let liveTime = this.$route.query.liveTime;
            let liveTime = this.$route.params.liveTime; //talon edit 0915
            let playDateData = liveTime.substring(0,liveTime.indexOf(' '));
            let plaTimeData = liveTime.substring(liveTime.indexOf(' ')+1,liveTime.length);
            this.startTimes = playDateData + " " +  plaTimeData.substring(0,plaTimeData.indexOf('-'));
            this.endTimes = playDateData + " " +  plaTimeData.substring(plaTimeData.indexOf('-')+1,plaTimeData.length);
            
            var date=new Date(); 
            
            //console.log(this.dateToString(date)); 
            let nowTimes = this.dateToString(date);
            this.startTimes =  this.startTimes.replace(/-/g,"/") // "2022/11/15 18:22";
            this.endTimes = this.endTimes.replace(/-/g,"/") //"2022/12/08 08:22";
            //console.log(this.endTimes,"====",this.CompareDate(this.endTimes,nowTimes));
            this.isYY = this.CompareDate(this.startTimes,nowTimes);
            this.isEnd = this.CompareDate(this.endTimes,nowTimes);
            this.startTimes = this.startTimes + ":00";
        },
       
        dateToString(now){ 
            var year = now.getFullYear(); 
            var month =(now.getMonth() + 1).toString(); 
            var day = (now.getDate()).toString(); 
            var hour = (now.getHours()).toString(); 
            var minute = (now.getMinutes()).toString(); 
            var second = (now.getSeconds()).toString(); 
            if (month.length == 1) { 
                month = "0" + month; 
            } 
            if (day.length == 1) { 
                day = "0" + day; 
            } 
            if (hour.length == 1) { 
                hour = "0" + hour; 
            } 
            if (minute.length == 1) { 
                minute = "0" + minute; 
            } 
            if (second.length == 1) { 
                second = "0" + second; 
            } 
            var dateTime = year + "-" + month + "-" + day +" "+ hour +":"+minute+":"+second; 
            return dateTime; 
        },
        CompareDate(d1,d2){
           return ((new Date(d1.replace(/-/g,"\/"))) > (new Date(d2.replace(/-/g,"\/"))));
        }
        
    },
}
</script>

<style lang="less" scoped>
.videoPlay {
    height: 100vh;
    background-color: #f5f5f5;
    // background-color: red;

    .vanTabs{

        .tabDiv{
            min-height:50vh;
            padding: 10px 16px;
        }
    }
}
</style>

